import React from 'react';
import styled from 'styled-components';
import { SourceLogo } from '..';
import { ImageCollection } from '../SourceLogo';

const LOGO_SIZE = 32;

const SRow = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid var(--c-border-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    flex-shrink: 0;
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
    &.flex {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.icon-cont {
      width: ${LOGO_SIZE}px;
    }
  }
  .b2,
  b2 {
    font-weight: ${(p) => (p.subtle ? 400 : 500)};
    color: ${(p) => (p.subtle ? 'var(--c-fg-1)' : 'var(--c-fg-0)')};
    line-height: 1;
  }
  &:last-child {
    border: none;
  }
`;

export interface UIRowProps {
  label: string;
  value: string;
  subtle?: boolean;
  icon?: {
    collection: ImageCollection;
    name: string;
  };
  options?: {
    alignForIcon?: boolean;
  };
}

export const Row = ({ label, value, subtle, icon, options }: UIRowProps) => {
  return (
    <SRow subtle={subtle}>
      {(icon || options?.alignForIcon) && (
        <div className="icon-cont">
          {!!icon && (
            <SourceLogo size={LOGO_SIZE} collection={icon?.collection} source={icon?.name} />
          )}
        </div>
      )}
      <div className="flex">
        <b2>{label}</b2>
        <b2>{value}</b2>
      </div>
    </SRow>
  );
};

export default Row;
