import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Plus } from '../../iconic/plus.svg';
import { ReactComponent as Minus } from '../../iconic/minus.svg';

const SStepper = styled.div`
  .stepper-val {
    width: 56px;
    text-align: right;
    padding-right: 8px;
  }
  .stepper-btn {
    background: var(--c-border);
    mix-blend-mode: var(--blend-color);
    border-radius: 99px;
    display: flex;
    align-items: center;
    svg {
      transform: scale(0.75);
    }
  }
`;

export const Stepper = ({ valueString }) => {
  return (
    <SStepper className="flex-h">
      <div className="stepper-btn">
        <Minus stroke="var(--c-fg-1)" />
      </div>
      <div className="stepper-val">{valueString}</div>
      <div className="stepper-btn">
        <Plus stroke="var(--c-fg-1)" />
      </div>
    </SStepper>
  );
};

export default Stepper;
