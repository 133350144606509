import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

const SAbstraction = styled.div`
  width: ${(p) => p.width};
  height: auto;
  border-radius: var(--br-large);
  background: ${(p) => p.color};
  color: var(--c-fg-0);
  margin-bottom: var(--s-grid-gap);
  padding: 24px;
  h3 {
    margin: 0 0 0;
  }
  .card-subtitle {
    margin-top: 0;
    color: var(--c-fg-1);
    font-weight: 400;
  }
  .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .card-footer {
    button {
      width: 100%;
    }
  }
`;

const SFlexible = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 12px;
`;

const SAspect = styled.div`
  padding: ${(p) => p.ratio * 100}% 0 0;
  position: relative;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 12px;
  }
`;

const Aspect = ({ ratio, hasFooter, children }) =>
  !ratio ? (
    <SFlexible hasFooter={hasFooter}>{children}</SFlexible>
  ) : (
    <SAspect hasFooter={hasFooter} ratio={ratio}>
      <div>{children}</div>
    </SAspect>
  );

export interface UIAbstractionProps {
  color?: string;
  value?: string;
  title?: string;
  subtitle?: string;
  aspect?: number;
  width?: string;
  children?: any;
  footer?: string;
}

export const Abstraction = ({
  color = 'var(--g-cerise-light)',
  value,
  title,
  width = '100%',
  children,
  footer,
  aspect = 0,
  subtitle,
}: UIAbstractionProps) => {
  return (
    <SAbstraction color={color} width={width} className="fadeInZoom">
      <Aspect ratio={!!aspect ? aspect : undefined} hasFooter={!!footer}>
        <div className="card-header">
          <b2>{title}</b2>
          <h3 className="wide nomargin sb">{value}</h3>
          {subtitle && <b2 className="card-subtitle">{subtitle}</b2>}
        </div>

        {!!children && <div className="card-body">{children}</div>}

        {!!footer && (
          <div className="card-footer">
            <Button
              color="var(--c-border-light)"
              textColor="var(--c-fg-0)"
              style={{ mixBlendMode: 'var(--blend)' }}
            >
              {footer}
            </Button>
          </div>
        )}
      </Aspect>
    </SAbstraction>
  );
};

export default Abstraction;
