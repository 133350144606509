import React, { useState } from 'react';
import styled from 'styled-components';
import { Page, Section, Split, AvatarGroup } from 'src/components';
import Abstraction from '../components/ui/Abstraction';
import Chart from '../components/ui/Chart';
import Row from '../components/ui/Row';
import { Stepper } from 'src/components/ui/Stepper';

const SInput = styled.input`
  border-radius: var(--radius-sm);
  padding: 8px 12px;
  border: none;
  font-family: var(--ff-base);
  font-size: var(--p);
  width: 10rem;
  margin-right: 0.5rem;
  background: var(--fgaa);
  color: var(--fg);
`;

const Components = ({ source = 'square', carrier = 'bcbs' }) => (
  <>
    <h3>Tax</h3>
    <Abstraction width="300px" title="Taxes" value="Q2" color="var(--g-orange-light)" />
    <Abstraction width="300px" title="Taxes added" value="27%" color="var(--g-orange-light)" />

    <h3>Income</h3>
    <Abstraction
      aspect
      width="500px"
      subtitle="Estimated income this year"
      value="$6,500"
      color="var(--g-cerise-light)"
    >
      <Chart />
      <br />
      <Row label="Gross" value="$3,000" />
      <Row label="Net" value="$2,000" subtle />
    </Abstraction>

    <h3>Paycheck</h3>
    <Abstraction width="350px" value="$500" color="var(--g-cerise-light)" footer="Roll">
      <Row label={source} value="$500" icon={{ collection: 'sources', name: source }} />
      <Row label="Taxes" value={<Stepper valueString="25%" />} options={{ alignForIcon: true }} />
      <Row
        label="Retirement"
        value={<Stepper valueString="5%" />}
        options={{ alignForIcon: true }}
      />
      <Row label="Time Off" value={<Stepper valueString="5%" />} options={{ alignForIcon: true }} />
    </Abstraction>

    <h3>Retirement</h3>
    <Abstraction
      width="300px"
      title="Retirement"
      value="SEP IRA"
      color="var(--g-teal-light)"
      footer="Activate"
    />
    <Abstraction width="500px" title="Projected value" value="$656,257" color="var(--g-teal-light)">
      <Chart />
    </Abstraction>

    <h3>Savings</h3>
    <Abstraction
      width="500px"
      title="Savings"
      value="$6,500"
      color="var(--g-lime-light)"
      footer="Insights"
    >
      <Chart />
    </Abstraction>

    <h3>Coverage</h3>

    <Abstraction width="350px" value="$500" color="var(--g-red-light)">
      <Row label="Silver HMO" icon={{ collection: 'carriers', name: carrier }} />
      <Row options={{ alignForIcon: true }} label="Lowest premium" />
      <Row options={{ alignForIcon: true }} label="$0 telehealth" />
      <Row options={{ alignForIcon: true }} label="Covers your prescriptions" />
      <AvatarGroup
        height={36}
        people={['jason', 'riley']?.map((person) => ({
          avatar: { url: `/avatars/${person}.jpg` },
        }))}
      />
    </Abstraction>
  </>
);

export default function Kit() {
  const [source, setSource] = useState('Doordash');
  const [carrier, setCarrier] = useState('bcbs');
  const [state, setState] = useState('texas');

  return (
    <Page hideLinks>
      <Section small nav title="UI Abstraction Kit" noFade>
        <SInput value={source} onChange={(e) => setSource(e.target.value)} type="text" />
        <SInput value={carrier} onChange={(e) => setCarrier(e.target.value)} type="text" />
        <SInput value={state} onChange={(e) => setState(e.target.value)} type="text" />
        <Split small top>
          <div className="pad">
            <Components source={source} carrier={carrier} state={state} />
          </div>
          <div className="dark pad" style={{ background: 'black' }}>
            <Components source={source} carrier={carrier} state={state} />
          </div>
        </Split>
      </Section>
    </Page>
  );
}
