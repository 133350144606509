import React from 'react';
import styled from 'styled-components';

const SChart = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 2%;
  flex: 1;
  width: 100%;
  min-height: 100px;
  padding: 24px 0 0;
  align-items: end;
`;

const SBar = styled.div`
  height: max(2px, ${(p) => p.height}%);
  background: ${(p) => (p.active ? 'var(--c-fg-0)' : 'var(--c-fg-3)')};
  mix-blend-mode: ${(p) => (p.active ? 'var(--c-fg-0)' : 'var(--blend-color)')};
  border-radius: 4px;
`;

const _data = [
  { active: false, done: true, value: 10 },
  { active: false, done: true, value: 7 },
  { active: false, done: true, value: 22 },
  { active: false, done: true, value: 72 },
  { active: false, done: true, value: 32 },
  { active: false, done: true, value: 50 },
  { active: true, done: true, value: 100 },
  { active: false, done: false, value: 0 },
  { active: false, done: false, value: 0 },
  { active: false, done: false, value: 0 },
];

export interface UIChartDataPoint {
  active?: boolean;
  done?: boolean;
  value: number;
}

export interface UIChartProps {
  data: Array<UIChartDataPoint>;
}

export const Chart = ({ data = _data }: UIChartProps) => {
  return (
    <SChart>
      {data?.map((d, idx) => (
        <SBar
          key={idx}
          className="chart-bar animateHeight"
          height={d.value}
          active={d.active}
          done={d.done}
        />
      ))}
    </SChart>
  );
};

export default Chart;
